<template>
  <div>
    <div class="content_block">
      <query-form label-width="100px">
        <template slot="action">
          <el-button type="primary" @click="handleSearch">查询</el-button>
          <el-button type="primary" size="mini" @click="exportExcel" style="margin-bottom: 8px">导出</el-button>
        </template>
        <el-form-item label-width="0">
          <check-tag v-model="query.dataSource" @change="handleSearch">
            <check-tag-item
              :label="i.name"
              :value="i.value"
              :key="i.value"
              v-for="i in DataSourceEnumList"
            ></check-tag-item>
          </check-tag>
        </el-form-item>
        <el-form-item label="设备名称" prop="deviceName">
          <el-input v-model.trim="query.deviceName" placeholder="请输入设备名称"></el-input>
        </el-form-item>
      </query-form>
    </div>
    <table-with-pagination
      :tableData="tableData"
      class="content_block"
      :columnList="columnList"
      :pagination.sync="pagination"
      :sortInfo.sync="createTimeSortInfo"
      @current-change="getTableList"
      @sort-change="getTableList"
    />
  </div>
</template>
<script>
import OrderApi from '@/api/order';
import { TablePaginationMixin } from '@/components/common/TableWithPagination';
import { DataSourceEnumList } from '@/enums/DataSourceEnum';
import { wrapWithAll } from '@/utils/Enum';
import BusinessScenarioEnum from '@/enums/BusinessScenarioEnum';
import dayjs from 'dayjs';

export default {
  name: 'EQUIPMENT_SERVICE_CAPABILITY_TB',
  mixins: [TablePaginationMixin],
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      BusinessScenarioEnum,
      DataSourceEnumList: wrapWithAll(DataSourceEnumList),
      columnList: [
        {
          tooltip: true,
          label: '序号',
          type: 'index',
          width: '50',
        },
        {
          tooltip: true,
          label: '设备分类',
          prop: 'deviceClassificationName',
        },
        {
          tooltip: true,
          label: '设备名称',
          prop: 'deviceName',
        },
        {
          tooltip: true,
          label: '规格型号',
          prop: 'spec',
        },
        {
          tooltip: true,
          label: '品牌',
          prop: 'brandName',
        },
        {
          tooltip: true,
          label: '生产商',
          minWidth: '150px',
          prop: 'enterpriseName',
        },
        {
          tooltip: true,
          label: '业务场景及业务数量',
          minWidth: '150px',
          prop: 'brand',
          render: (h, { row }) => {
            let actions = [];

            row.businessScenarioCountList.forEach(v => {
              actions.push(
                h(
                  'el-tag',
                  {
                    props: {
                      effect: 'dark',
                      type: v.count === null ? 'info' : v.businessScenario === 'MAINTENANCE_SCENARIO' ? 'success' : 'warning',
                    },
                    style: {
                      marginRight: '5px',
                    },
                  },
                  `${BusinessScenarioEnum[v.businessScenario]}${v.count === null ? '--' : v.count}`,
                ),
              );
            });
            return h('div', actions);
          },
        },
        {
          tooltip: true,
          label: '数据来源',
          minWidth: '150px',
          prop: 'dataSourceName',
          filters: [
            { label: '手工新增', value: 'ADD_MANUALLY' },
            { label: '工单引入', value: 'WORK_ORDER_IMPORT'},
          ],
          filterMethod: ({option, row}) => {
            return option.value === row.dataSource;
          },
          render: (h, { row }) => {
            return h('div', {
              style: {
                color: row.dataSource === 'ADD_MANUALLY' ? '#69c03b' : '#3fa1fd',
              },
            }, row.dataSourceName);
          },
        },
      ],
      query: {
        dataSource: null,
        deviceName: '',
      },
      tableData: [],
    };
  },
  methods: {
    download ({ data, type, fileTitle }) {
      let blob = new Blob([data], { type });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileTitle;
      document.querySelectorAll('body')[0].appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    exportExcel () {
      OrderApi.exportDeviceServiceExcel({
        employeeId: this.id,
        ...this.query,
      }).then(({ data }) => {
        this.download({
          data: data,
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          fileTitle: `设备服务能力_${dayjs(Date.now()).format('YYYYMMDDHHmmss')}`,
        });
      });
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTableList();
    },
    getTableList () {
      OrderApi.deviceServicePage({
        ...this.query,
        employeeId: this.id,
        ...this.createTimeSortInfo,
        ...this.pagination,
        pageNum: this.pagination.currentPage,
      }).then(res => {
        this.tableData = res.body?.list || [];
        this.pagination.total = res.body?.total || 0;
      });
    },
  },
};
</script>
